const mutations = {
  loginUser (state, payload) {
    state.user = payload
    state.authenticaded = true
  },

  logoutUser (state) {
    state.user = {}
    state.authenticaded = false
  },

  setState (state, payload) {
    state.state = payload
  }
}

export default mutations
const mutations = {
  addToCart(state, payload) {
    state.cart.push(payload);

    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  createCart(state, payload) {
    state.cart = payload;
  },

  updateCartItems(state, payload) {
    state.cartItens = payload;

    state.order.items = [];

    payload.items.map((item) => {
      state.order.items.push({
        quantity: 1,
        product: item,
      });
    });
  },

  clearCart(state) {
    state.cart = [];
    state.cartItens = [];

    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  updateOrder(state, payload) {
    state.order = payload;
  },
};

export default mutations;

export default {
  authenticaded: false,
  state: {
    acronym: "SP",
    name: "São Paulo"
  },
  user: {
    firstname: "",
    lastname: ""
  }
}

import { verifyAuth } from "@/services/auth"
import { refreshToken } from "@/services/utils"

export default {
  loginUser (context, payload) {
    context.commit('loginUser', payload)
  },

  logoutUser (context) {
    localStorage.removeItem('token')
    localStorage.removeItem('refresh')

    context.commit('logoutUser')
  },

  setState (context, payload) {
    localStorage.setItem('state', payload.acronym)
    localStorage.setItem('stateName', payload.name)

    context.commit('setState', payload)
  },

  async verifyAuth(context) {
    if (localStorage.token) {
      await verifyAuth()
        .then(response => {
          context.dispatch('loginUser', { ...response })
        })
        .catch(async (error) => {
          if (error.code == 'ERR_NETWORK') {
            await refreshToken()
              .then(async (response) => {
                localStorage.setItem("refresh", response.refreshToken)
                localStorage.setItem('token', response.accessToken)

                await verifyAuth()
                  .then(response => {
                    context.dispatch('loginUser', { ...response })
                  })
              })

            return
          }
          
          context.dispatch('logoutUser')
        })
    } else {
      context.dispatch('logoutUser')
    }
  }
}
import ApiService from "../index";

export const searchProducts = async (payload, page = 1, take = 8) => {
  const URI = `/product/search?take=${take}&page=${page}`;

  const response = await ApiService.post(URI, payload);

  return response.data;
};

export const getCartItems = async (payload) => {
  const URI = `/product/searchById`;

  const response = await ApiService.post(URI, payload);

  return response.data;
};

export const categories = async () => {
  const URI = `/product/categories`;

  const response = await ApiService.get(URI);

  return response.data;
};

export const mainCategories = async () => {
  const URI = `/product/mainCategories`;

  const response = await ApiService.get(URI);

  return response.data;
};

export const calculateCart = async (payload) => {
  const URI = "/product/calculate";

  const response = await ApiService.post(URI, payload);

  return response.data;
};

export const productDetails = async (productId) => {
  const URI = `/product/details?productId=${productId}`;

  const response = await ApiService.get(URI);

  return response.data;
};

export const productsOrder = async () => {
  const URI = "/aux/filter/order";

  const response = await ApiService.get(URI);

  return response.data;
};

export const allApplications = async () => {
  const URI = "/product/applications/all";

  const response = await ApiService.get(URI);

  return response.data;
};

export const getPriceRange = async () => {
  const URI = "/aux/filter/price";

  const response = await ApiService.get(URI);

  return response.data;
};

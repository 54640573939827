export default {
  setCategory (context, payload) {
    context.commit('setCategory', payload)
  },

  setApplication (context, payload) {
    context.commit('setApplication', payload)
  },

  setQuery (context, payload) {
    context.commit('setQuery', payload)
  },

  setTag (context, payload) {
    context.commit('setTag', payload)
  }
}
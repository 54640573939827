const gettters = {
  getCategories (state) {
    return state.categories
  },

  getApplications (state) {
    return state.applications
  },

  getQuery (state) {
    return state.query
  },

  getTags (state) {
    return state.tags
  }
}

export default gettters
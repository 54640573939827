import ApiService from "../index";

export const login = async (payload) => {
  const URI = '/auth'

  const response = await ApiService.post(URI, payload)

  return response.data
}

export const verifyAuth = async () => {
  const URI = '/user'

  ApiService.setHeader()

  const response = await ApiService.get(URI)

  return response.data
}

export const addUser = async (payload) => {
  const URI = '/auth/register'

  const response = await ApiService.post(URI, payload)

  return response.data
}

export const forgotPassword = async (payload) => {
  const URI = '/auth/forgot'

  const response = await ApiService.post(URI, payload)

  return response.data
}

export const saveNewPassword = async (payload) => {
  const URI = '/auth/password/change'

  const response = await ApiService.post(URI, payload)

  return response.data
}
<template>
  <div class="card">
    <b-row>
      <b-col
        v-for="n in 8"
        :key="n"
        cols="3"
        class="d-flex justify-content-center gy-3"
      >
        <VueSkeletonLoader
          type="square"
          animation="fade"
          :size="100"
          rounded
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: "CardSkeleton",

  components: { VueSkeletonLoader },
};
</script>

<style>

</style>
<template>
  <div class="card">
    <VueSkeletonLoader
      type="rect"
      animation="fade"
      rounded
      :height="300"
      class="w-100"
    />
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: "CardSkeleton",

  components: { VueSkeletonLoader },
};
</script>

<style scoped>
</style>
import { getCartItems } from "@/services/product"

export default {
  addToCart (context, payload) {
    context.commit('addToCart', payload)

    context.dispatch('updateCartItems')
  },

  createCart (context) {
    if (localStorage.getItem('cart')) {
      const cart = JSON.parse(localStorage.getItem('cart'))

      context.commit('createCart', cart)

      context.dispatch('updateCartItems')
    } else {
      context.commit('createCart', [])
    }
  },

  removeItem (context, payload) {
    if (localStorage.getItem('cart')) {
      const cart = JSON.parse(localStorage.getItem('cart'))
      const indexToRemove = cart.indexOf(payload)
      
      cart.splice(indexToRemove, 1)

      localStorage.setItem('cart', JSON.stringify(cart))

      context.dispatch('createCart')
    }
  },
  
  async updateCartItems (context) {
    const cart = JSON.parse(localStorage.getItem('cart'))

    await getCartItems(cart)
      .then(response => {
        context.commit('updateCartItems', response)
      })
      .catch(error => {
        console.log("Algo deu errado recuperando o carrinho: ", error)
      })
  },

  updateOrder (context, payload) {
    context.commit('updateOrder', payload)
  },

  clearCart (context) {
    context.commit('clearCart')
  }
}
import Vue from "vue";
import Router from "vue-router";
import store from "../store";

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: () => import("../layout/index.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/home/index.vue"),
        meta: {
          showNavApplication: true,
        },
      },
      {
        path: "/login",
        name: "login",
        component: () => import("../views/login/index.vue"),
        meta: {
          pageTitle: "Login",
          breadcrumbs: ["Home", "Login"],
          showNavApplication: true,
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("../views/profile/index.vue"),
        meta: {
          pageTitle: "Perfil",
          breadcrumbs: ["Home", "Perfil"],
        },
      },
      {
        path: "/cart",
        name: "cart",
        component: () => import("../views/cart/index.vue"),
        meta: {
          pageTitle: "Checkout",
          breadcrumbs: ["Home", "Carrinho"],
          showNavApplication: false,
        },
      },
      {
        path: "/search",
        name: "search",
        component: () => import("../views/search/index.vue"),
        meta: {
          pageTitle: "Busca",
          breadcrumbs: ["Home", "Busca"],
          showNavApplication: true,
        },
      },
      {
        path: "/product/:id",
        name: "product",
        component: () => import("../views/product/index.vue"),
        meta: {
          pageTitle: "Produto",
          breadcrumbs: ["Home", "Produto"],
          showNavApplication: true,
        },
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import("../views/contact/index.vue"),
        meta: {
          pageTitle: "Contato",
          breadcrumbs: ["Home", "Contato"],
          showNavApplication: true,
        },
      },
      {
        path: "/privacy",
        name: "privacy",
        component: () => import("../views/privacy/index.vue"),
        meta: {
          pageTitle: "Política de privacidade",
          breadcrumbs: ["Home", "Privacidade"],
        },
      },
      {
        path: "/terms",
        name: "terms",
        component: () => import("../views/terms/index.vue"),
        meta: {
          pageTitle: "Termos e condições",
          breadcrumbs: ["Home", "Termos"],
        },
      },
      {
        path: "/changePolicy",
        name: "chanage-policy",
        component: () => import("../views/change-policy/index.vue"),
        meta: {
          pageTitle: "Troca e devolução",
          breadcrumbs: ["Home", "Troca e devolução"],
        },
      },
      {
        path: "/distributor/:id",
        name: "distributor",
        component: () => import("../views/distributor/index.vue"),
        meta: {
          pageTitle: "Distribuidor",
          breadcrumbs: ["Home", "Produto", "Distribuidor"],
          showNavApplication: false,
        },
      },
      {
        path: "/recoverPassword",
        name: "recover-password",
        component: () => import("../views/recover/index.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
  },
];

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (store.getters["auth/getIsAuthenticaded"]) {
    await store.dispatch("auth/verifyAuth");
  }
  next();
});

export default router;

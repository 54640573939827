<template>
  <router-view />
</template>

<script>
export default {
  name: "MacomApp",

  created() {
    this.$store.dispatch("auth/verifyAuth");
    this.$store.dispatch("cart/createCart");
  },
};
</script>


<style lang="scss">
@import "assets/scss/style";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  @media (max-width: 768px) {
    padding-left: 0px;
  }

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<template>
  <div>
    <component v-if="loading" :is="component" />

    <div v-else-if="!information" class="card">
      <div class="text-center p-5">
        <h3 class="align-items-center subtitle">
          {{ textNoData }}
        </h3>
      </div>
    </div>

    <slot v-else />
  </div>
</template>

<script>
import CardSkeleton from "@/components/load-skeletons/CardSkeleton.vue";
import ProductsSkeleton from "@/components/load-skeletons/ProductsSkeleton.vue";
import HeroSkeleton from "@/components/load-skeletons/HeroSkeleton.vue";

const componentsList = {
  card: CardSkeleton,
  products: ProductsSkeleton,
  hero: HeroSkeleton,
};

export default {
  name: "LoadComponent",

  props: {
    name: { type: String, default: "card" },
    loading: { type: Boolean, required: true },
    information: { type: Boolean, required: true },
    textNoData: { type: String, default: "Não há dados" },
  },

  computed: {
    component() {
      return componentsList[this.name];
    },
  },
};
</script>

<style>
</style>
import ApiService from "../index";

export const location = async (payload) => {
  const URI = "/aux/address/state/location";

  const response = await ApiService.post(URI, payload);

  return response.data;
};

export const userIP = async () => {
  const response = await fetch("https://api.ipify.org?format=json");
  const data = await response.json();

  return data;
};

export const refreshToken = async () => {
  const URI = "/auth/refresh";

  const response = await ApiService.post(URI, {
    refreshToken: localStorage.getItem("refresh"),
  });

  return response.data;
};

export const acceptCookies = async (payload) => {
  const URI = "/aux/cookies";

  const response = await ApiService.post(URI, payload);

  return response.data;
};

export const contact = async (payload) => {
  const URI = "/aux/contact";

  const response = await ApiService.post(URI, payload);

  return response.data;
};

export const distributor = async (id) => {
  const URI = `/aux/distributor?id=${id}`;

  const response = await ApiService.get(URI);

  return response.data;
};

export const privacyPolicy = async () => {
  const URI = "/aux/privacyPolicy";

  const response = await ApiService.get(URI);

  return response.data;
};

export const termsOfCondition = async () => {
  const URI = "/aux/terms";

  const response = await ApiService.get(URI);

  return response.data;
};

export const changePolicy = async () => {
  const URI = "/aux/changePolicy";

  const response = await ApiService.get(URI);

  return response.data;
};

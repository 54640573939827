const gettters = {
  getAuthenticadedUser (state) {
    return state.user
  },

  getIsAuthenticaded (state) {
    return state.authenticaded
  },

  getState (state) {
    return state.state
  }
}

export default gettters
const mutations = {
  setCategory (state, payload) {
    state.categories = payload
  },

  setApplication (state, payload) {
    state.applications = payload
  },

  setQuery (state, payload) {
    state.query = payload
  },

  setTag (state, payload) {
    state.tags = payload 
  }
}

export default mutations
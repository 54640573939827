<template>
  <b-row>
    <b-col
      cols="12"
      xl="6"
      class="p-4"
    >
      <VueSkeletonLoader
        type="rect"
        animation="fade"
        rounded
        class="mb-4"
      />

      <VueSkeletonLoader
        type="rect"
        animation="fade"
        rounded
        :height="200"
        class="w-100 mb-4"
      />

      <VueSkeletonLoader
        type="rect"
        animation="fade"
        rounded
      />

      <b-row>
        <b-col></b-col>
      </b-row>
    </b-col>

    <b-col
      cols="12"
      xl="6"
      class="p-4"
    >
      <VueSkeletonLoader
        class="w-100"
        type="rect"
        animation="fade"
        rounded
        :height="500"
      />
    </b-col>
  </b-row>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: "HeroSkeleton",

  components: { VueSkeletonLoader },
};
</script>

<style scoped>

</style>
export default {
  cart: [],
  cartItens: [],
  order: {
    items: [],
    address: {},
    billingAddress: {},
    payment: {
      type: "creditCard",
      value: {
        id: ""
      }
    },
    total: {
      price: "",
      full: "",
      freight: ""
    }
  }
}

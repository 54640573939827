const gettters = {
  getCart (state) {
    return state.cart
  },

  getCartItens (state) {
    return state.cartItens
  },

  getOrder (state) {
    return state.order
  }
}

export default gettters